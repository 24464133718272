
const $body = $('body');

/////////////　関数 /////////////
var scrollTop;

//スクロールを固定
function bodyFixedOn() {
  scrollTop = $(window).scrollTop();

  $body.css({
    position: 'fixed',
    top: -scrollTop,
    'overflow-y': 'hidden'
  });
  $('.l-header').addClass('scroll-hidden');
  $('.f-body-wrap').addClass('f-body-gutter');
}

//スクロールの固定を解除
function bodyFixedOff() {

  $body.css({
    position: '',
    top: '',
    'overflow-y': ''
  });
  $('.l-header').removeClass('scroll-hidden');
  $('.f-body-wrap').removeClass('f-body-gutter');
  $(window).scrollTop(scrollTop);
}

/* ==========================================================================
  humberger
========================================================================== */

var humberger_icon = $('#js-icon');
var humberger_menu = $('#js-menu');

humberger_icon.click(function(){
  $(this).toggleClass('open');

  if (humberger_icon.hasClass('open')) {
    bodyFixedOn();
    humberger_menu.addClass('active');
  } else {
    bodyFixedOff();
    humberger_menu.removeClass('active');
  }
});

/* ==========================================================================
  smooth scroll
========================================================================== */
var height_h = '';
if(!document.URL.match(/lineup/)){
  height_h = $('.l-header').height();
}else{
  height_h = ''
}
$('a[href^="#"]').click(function() {
  var speed = 500;
  var href= $(this).attr('href');
  var target = $(href == '#' || href == '' ? 'html' : href);
  var position = target.offset().top - height_h;
  $('body,html').animate({scrollTop:position}, speed, 'swing');
  return false;
});

